import {mapMutations, mapActions, mapGetters} from "vuex";
import {dynamicHead} from "../../../mixins/dynamic-head";

//sections


export default {
  name: "public_offer",
  components: {},
  mixins: [dynamicHead],
  data() {
    return {
      crumbs: [
        {src: 'home', title: this.$t('home.title'), slug: ''},
      ],

    }
  },
  mounted() {
    this.getContent('public-offer-agreement').then(() => {
      this.setPageItem(this.pageContent)
      document.title = `${this.pageContent.title} | karbosnab`
      this.crumbs.push({
        src: 'articles', title: this.pageContent.title, slug: 'articles'
      })
      this.setRequestFlag(true);
    })
  },
  created() {

  },
  computed: {
    ...mapGetters({
      globalRequestsFlag: 'system/globalRequestsFlag',
      pageContent: 'landings/landingContent',
    })
  },

  methods: {
    ...mapActions({
      getContent: 'landings/GET_LENDING'
    }),
    ...mapMutations({
      setRequestFlag: 'system/SET_REQUESTS_FLAG',
    }),
  }
}
